import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Mail,
  WarningPolygon,
  Whatsapp,
  CheckMarkCircle,
} from "react-huge-icons/outline";
import Checkbox from "../checkbox/checkbox";
import UploadedFileInfo from "./uploadedFileInfo";
import DashboardSubmit from "./dashboardSubmit";
import { useUploadStore } from "../../stores/uploadFirmwareStore";
import { Patch } from "../../models/firmware";
import { isNullOrEmpty } from "../../helpers/stringHelper";
import { ConstantInfo } from "../../core/constantInfo";
import TgFlashSlaveInfo from "./tgFlashSlaveInfo";
import { useOfflineSettingsStore } from "../../stores/offlineSettingsStore";
import { useAuthStore } from "../../stores/authStore";
import UploadSecondEcu from "./uploadSecondEcu";

const UploadedDashboard: React.FC = () => {
  const { t } = useTranslation();
  const { firmwareInfo, setFirmwareInfo } = useUploadStore();
  const {
    checkedOnlinePatches,
    setCheckedOnlinePatches,
    checkedOfflinePatches,
    setCheckedOfflinePatches,
    plusPatchPrice,
    setMaxPatchPrice,
    setPlusPatchPrice,
    stockFile,
    setStockFile,
    fixFile,
    setFixFile,
  } = useUploadStore();
  const { comment, setComment } = useUploadStore();
  const { firmwareProcessResult } = useUploadStore();
  const ONLINE = 0;
  const OFFLINE = 1;
  const TGFLASH = 2;

  const { offlineSettings, fetchOfflineSettings } = useOfflineSettingsStore();
  const { lang } = useAuthStore();

  useEffect(() => {
    fetchOfflineSettings();
  }, []);

  const offlineSettingsForCurrentLanguage = offlineSettings.find(
    (f) => f.lang === lang
  );

  //********************* */

  const handleCheckboxOnlineChange = (
    isChecked: boolean,
    checkboxText: string
  ) => {
    let updatedPatches: Patch[];
    const patch = firmwareInfo?.patches.find(
      (x) => x.name === checkboxText && x.patchMode === ONLINE
    )!;
    if (isChecked) {
      updatedPatches = [...checkedOnlinePatches, patch];
    } else {
      updatedPatches = checkedOnlinePatches.filter(
        (p) => p.name !== patch.name
      );
    }

    setCheckedOnlinePatches(updatedPatches);
    updatedPatches = [...updatedPatches, ...checkedOfflinePatches];

    const maxPrice =
      updatedPatches.length > 0
        ? Math.max(...updatedPatches.map((patch) => patch.price))
        : null;

    setMaxPatchPrice(maxPrice);

    const plusPrice =
      updatedPatches.length > 0
        ? updatedPatches.reduce((sum, current) => sum + current.pricePlus, 0)
        : null;

    setPlusPatchPrice(plusPrice);
  };

  const handleCheckboxOfflineChange = (
    isChecked: boolean,
    checkboxText: string
  ) => {
    let updatedPatches: Patch[];
    const patch = firmwareInfo?.patches.find(
      (x) => x.name === checkboxText && x.patchMode === OFFLINE
    )!;
    if (isChecked) {
      updatedPatches = [...checkedOfflinePatches, patch];
    } else {
      updatedPatches = checkedOfflinePatches.filter(
        (p) => p.name !== patch.name
      );
    }

    setCheckedOfflinePatches(updatedPatches);
    updatedPatches = [...updatedPatches, ...checkedOnlinePatches];

    const maxPrice =
      updatedPatches.length > 0
        ? Math.max(...updatedPatches.map((patch) => patch.price))
        : null;

    setMaxPatchPrice(maxPrice);

    const plusPrice =
      updatedPatches.length > 0
        ? updatedPatches.reduce((sum, current) => sum + current.pricePlus, 0)
        : null;

    setPlusPatchPrice(plusPrice);
  };

  const switchStockState = () => {
    setStockFile(!stockFile);
  };

  const switchFixState = () => {
    setFixFile(!fixFile);
  };

  const onlinePatches =
    firmwareInfo?.patches.filter((patch) => patch.patchMode === ONLINE) ?? [];
  const offlinePatches =
    firmwareInfo?.patches.filter((patch) => patch.patchMode === OFFLINE) ?? [];
  const tgflashPatches =
    firmwareInfo?.patches.filter((patch) => patch.patchMode === TGFLASH) ?? [];

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="md:hidden">
          <UploadedFileInfo />
        </div>

        <div className="flex-1 flex flex-row">
          <div
            className={`md:w-2/3 max-md:w-full ${
              firmwareProcessResult ? "opacity-50 pointer-events-none" : ""
            }`}
          >
            {(firmwareInfo?.error ?? "") && (
              <div className="rounded-xl bg-content2 p-8 mb-8 relative overflow-hidden">
                <h1 className="text-2xl text-danger font-medium pb-5">
                  {firmwareInfo?.error}
                </h1>
              </div>
            )}

            {firmwareInfo?.isFixed === false &&
              isNullOrEmpty(firmwareInfo?.error) && (
                <div className="rounded-xl bg-content2 p-8 mb-8 relative overflow-hidden">
                  <div className="absolute -right-11 top-2/3 transform -translate-y-1/2">
                    <WarningPolygon className="h-56 w-56 opacity-10" />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="text-2xl font-medium pb-5">
                      {t("Attention, your file")}{" "}
                      <span className="text-danger">
                        {t("incorrect for Kess entry")}
                      </span>
                    </h1>
                    <p className="text-textsecondary pb-5">
                      {t("If you use Kess programmator made Fix")}
                    </p>

                    <Checkbox
                      key={"fix"}
                      id={"fix"}
                      checkboxText={t("Fix")}
                      tooltipText={
                        offlineSettingsForCurrentLanguage?.records.find(
                          (x) => x.header === "Fix"
                        )?.description ?? ""
                      }
                      checked={fixFile === true}
                      onChange={() => switchFixState()}
                    />
                  </div>
                </div>
              )}

            {firmwareInfo?.isInStock === false &&
              isNullOrEmpty(firmwareInfo?.error) && (
                <div className="rounded-xl bg-content2 p-8 mb-8 relative overflow-hidden">
                  <div className="absolute -right-11 top-2/3 transform -translate-y-1/2">
                    <WarningPolygon className="h-56 w-56 opacity-10" />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="text-2xl font-medium pb-5">
                      {t("Attention, in your file")}{" "}
                      <span className="text-danger">
                        {t("is not original")}
                      </span>
                    </h1>
                    <p className="text-textsecondary pb-5">
                      {t(
                        "Recommended to return the file to stock and upload it again for processing"
                      )}
                    </p>

                    <Checkbox
                      key={"stock"}
                      id={"stock"}
                      checkboxText={t("Stock")}
                      tooltipText={
                        offlineSettingsForCurrentLanguage?.records.find(
                          (x) => x.header === "Stock"
                        )?.description ?? ""
                      }
                      checked={stockFile === true}
                      onChange={() => switchStockState()}
                    />
                  </div>
                </div>
              )}

            {((firmwareInfo?.isInStock === true &&
              firmwareInfo?.isFixed === true) ||
              (firmwareInfo?.isInStock === true &&
                firmwareInfo?.isFixed == null) ||
              (firmwareInfo?.isFixed === true &&
                firmwareInfo?.isInStock == null)) && (
              <div className="rounded-xl bg-content2 p-8 mb-8 relative overflow-hidden">
                <div className="absolute -right-11 top-2/3 transform -translate-y-1/2">
                  <CheckMarkCircle className="h-56 w-56 opacity-10" />
                </div>
                <div className="flex flex-col">
                  <h1 className="text-2xl font-medium pb-2">
                    {t("In Your file")}{" "}
                    <span className="text-success">
                      {t("calibration area (maps) is original")}
                    </span>
                  </h1>
                </div>
              </div>
            )}

            {firmwareInfo?.isTwoEcu === true && isNullOrEmpty(firmwareInfo?.error) && (
              <div>
                <UploadSecondEcu />
              </div>
            )}

            {onlinePatches.length > 0 && isNullOrEmpty(firmwareInfo?.error) && (
              <div className="rounded-xl bg-content2 mb-8">
                <div className="pt-8 px-8">
                  <h1 className="text-2xl font-medium pb-5">
                    {t("Online service")}
                  </h1>
                  <p className="text-textsecondary pb-5">
                    {t(
                      "Upload your file, select options, and it will be processed by the server in 10-15 seconds"
                    )}
                  </p>
                </div>

                <div className="p-8 flex flex-wrap">
                  {onlinePatches.map((patch, index) => (
                    <Checkbox
                      key={index + "online"}
                      id={index + "online"}
                      checkboxText={patch.name}
                      tooltipText={
                        offlineSettingsForCurrentLanguage?.records.find(
                          (x) => x.header === patch.name
                        )?.description ?? ""
                      }
                      checked={checkedOnlinePatches.some(
                        (p) => p.name === patch.name
                      )}
                      onChange={(isChecked, checkboxText) =>
                        handleCheckboxOnlineChange(isChecked, checkboxText)
                      }
                    />
                  ))}
                </div>
              </div>
            )}

            {offlinePatches.length > 0 &&
              isNullOrEmpty(firmwareInfo?.error) && (
                <div className="rounded-xl bg-content2 mb-8 ">
                  <div className="">
                    <div className="flex flex-row">
                      <div className="p-8">
                        <h1 className="text-2xl font-medium pb-5">
                          {t("Offline service")}
                        </h1>
                        <p className="text-textsecondary">
                          {t(
                            "Upload your file, specify the required options. Our engineers will process it within 1-2 hours during business hours. For clarification, please contact support"
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="flex md:flex-row max-md:flex-col">
                      <div className="flex flex-row items-center pl-8 pr-8">
                        <Whatsapp className="w-8 h-8 my-3 pr-2" />
                        <p className="pr-1">(Whatsapp)</p>
                        <a href={ConstantInfo.phone}>
                          {ConstantInfo.phoneView}
                        </a>
                      </div>
                      <div className="flex flex-row items-center max-md:pl-8">
                        <Mail className="w-8 h-8 my-3 pr-2" />
                        <a
                          className="pr-2"
                          href={ConstantInfo.email + "Offline service"}
                        >
                          {ConstantInfo.emailView}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="lg:p-3 xl:p-8 flex flex-wrap">
                    {offlinePatches.map((patch, index) => (
                      <Checkbox
                        key={index + "offline"}
                        id={index + "offline"}
                        checkboxText={patch.name}
                        tooltipText={
                          offlineSettingsForCurrentLanguage?.records.find(
                            (x) => x.header === patch.name
                          )?.description ?? ""
                        }
                        checked={checkedOfflinePatches.some(
                          (p) => p.name === patch.name
                        )}
                        onChange={(isChecked, checkboxText) =>
                          handleCheckboxOfflineChange(isChecked, checkboxText)
                        }
                      />
                    ))}
                  </div>

                  <div className="p-8">
                    <p className="pb-5">{t("Your comments about the order")}</p>

                    <textarea
                      className="h-40 rounded-2xl bg-content3 w-full mb-5 px-5 py-3 resize-none"
                      placeholder={t("Write message")}
                      value={comment}
                      onChange={(event) => setComment(event.target.value)}
                    />
                  </div>
                </div>
              )}

            {tgflashPatches.length > 0 && (
              <TgFlashSlaveInfo patches={tgflashPatches} />
            )}
          </div>

          <div className="lg:w-4/12 xl:w-1/3 max-md:hidden">
            <div className="sticky z-10 top-28">
              <div className="lg:px-2 xl:px-8">
                <UploadedFileInfo />
              </div>

              <DashboardSubmit />
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 bg-secondary w-full rounded-t-xl -ml-6 md:hidden">
        <DashboardSubmit />
      </div>
    </>
  );
};

export default UploadedDashboard;
