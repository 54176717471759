import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConstantRoutes } from "../../../core/constantRoutes";
import { useNavigate, useParams } from "react-router-dom";
import useOfflineServiceStore from "../../../stores/admin/offlineServiceStore";
import useBreadcrumbsStore from "../../../stores/breadcrumbStore";
import OfflineService from "../../../api/admin/offlineService";
import AdminTableServiceRecordInfo from "./adminTableServiceRecordInfo";
import AdminTableServiceRecordUploadFile from "./adminTableServiceRecordUploadFile";
import AdminTableServiceRecordChat from "./adminTableServiceRecordChat";
import AdminTableServiceRecordUploadEcuFile from "./adminTableServiceRecordUploadEcuFile";

interface AdminTableServiceRecordProps {
  label: string,
  path: string,
}

const AdminTableServiceRecord: React.FC<AdminTableServiceRecordProps> = ({label, path}) => {
  const { t } = useTranslation();
  const { order, fetchOrder,  } = useOfflineServiceStore();
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();
  const { file } = useParams<{ file: string }>();
  const navigate = useNavigate();
  const offlineService = new OfflineService();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({
      label: label,
      path: path,
    });
    addBreadcrumb({
      label: file ?? "",
      path: path + "/work/" + file,
    });

    fetchOrder(Number(file));
  }, []);

  return (
    <>
      <AdminTableServiceRecordInfo />

      <AdminTableServiceRecordUploadFile />

      {order?.isTwoEcu === true && 
        <AdminTableServiceRecordUploadEcuFile />
      }

      <div className="pt-8"> 
        <AdminTableServiceRecordChat />
      </div>

    </>
  );
};

export default AdminTableServiceRecord;
