import React, { ChangeEvent, DragEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../checkbox/checkbox";
import { useUploadStore } from "../../stores/uploadFirmwareStore";
import UploadFirmwareService from "../../api/uploadFirmwareService";

const UploadSecondEcu: React.FC = () => {
  const { t } = useTranslation();
  const {
    twoEcu,
    setTwoEcu,
    selectedEcuFile,
    setSelectedEcuFile,
    setEcuTwoUploaded,
  } = useUploadStore();

  const switchTwoEcuState = async () => {
    setTwoEcu(!twoEcu);
  };

  const [highlighted, setHighlighted] = useState(false);
  const [inWork, setInWork] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFileInfo, setUploadedFileInfo] = useState("");

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedEcuFile(file);
      await uploadFile(file);
      setEcuTwoUploaded(true);
    }
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      setSelectedEcuFile(file);
      await uploadFile(file);
      setEcuTwoUploaded(true);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = () => {
    setHighlighted(false);
  };

  const uploadFile = async (file: File) => {
    console.log(inWork);
    if (inWork) return;

    if (file) {
      const uploader = new UploadFirmwareService();
      try {
        var uploadedFileInfo = await uploader.uploadEcuFile(file, {
          onProgress: (progress) => {
            setUploadProgress(progress);
          },
        });
        setInWork(true);
        setUploadProgress(0);
      } catch (error) {
        // Handle error
        console.error("Error uploading file:", error);
      }
    } else {
      // Handle case when no file is selected
      console.error("No file selected for upload.");
    }
  };

  return (
    <>
      <div className="bg-content2 rounded-xl p-4 mb-4">
        <Checkbox
          key={"twoECU"}
          id={"twoECU"}
          checkboxText={t("Upload ECU2")}
          tooltipText={""}
          checked={twoEcu === true}
          onChange={() => switchTwoEcuState()}
        />

        <p className="text-textsecondary pb-5">{t("twoECU")}</p>

        {twoEcu && (
          <div
            className={`flex-1 flex flex-col justify-center items-center border border-divider rounded-xl border-dashed h-72             
                ${highlighted ? "border-primary bg-content2" : ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            {selectedEcuFile && (
              <div className="mt-4 relative">
                <div className="relative z-10">
                  <h1 className="text-2xl pb-4"> {t("Upload progress")}</h1>
                  <p className="hidden md:block text-textsecondary text-sm pb-4">
                    {t("Wait until the file is uploaded")}
                  </p>

                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-tablebg2">
                    <div
                      style={{ width: `${uploadProgress}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-primary transition-width duration-500 ease-in-out"
                    ></div>
                  </div>
                </div>
              </div>
            )}

            {inWork && (
              <div className="text-center">
                <h1 className="text-2xl pb-4">{t("Upload ECU2")}</h1>
                <button
                  className="flex items-center justify-center border-primary border border-solid rounded-lg px-3 
                      mt-5
                      cursor-pointer hover:bg-primary h-11 w-full transition duration-300 ease-in-out"
                  onClick={() => {
                    setEcuTwoUploaded(false);
                    setSelectedEcuFile(null);
                    setInWork(false);
                  }}>
                  {t("Upload new file")}
                </button>

                <p className="hidden md:block text-textsecondary pb-4">
                  {t("ECU2 file uploaded succesfully")}
                </p>
              </div>
            )}

            {!selectedEcuFile && !inWork && (
              <div className="text-center">
                <h1 className="text-2xl pb-4">{t("Upload ECU2")}</h1>
                <p className="hidden md:block text-textsecondary pb-4">
                  {t(
                    "Drag the file to the highlighted area and click upload file"
                  )}
                </p>
                <div className="flex justify-center">
                  <div className="py-5">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="hidden"
                      id="fileInput"
                    />
                    <label
                      htmlFor="fileInput"
                      className="text-center cursor-pointer py-5 px-14 rounded-lg bg-primary"
                    >
                      {t("Upload ECU2")}
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UploadSecondEcu;
