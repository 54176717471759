import { t } from "i18next";
import React from "react";
import { useUploadStore } from "../../stores/uploadFirmwareStore";
import { convertUTCDateToLocalDateString } from "../../helpers/dateHelper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UploadSecondEcu from "./uploadSecondEcu";

const UploadedFileInfo: React.FC = () => {
  const { firmwareInfo, setFirmwareInfo, setSelectedFile, setFileUploaded } =
    useUploadStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <p className="text-xl">{t("File info")}</p>
      <div className="">
        <div className="mt-4 md:p-1 xl:p-5 border rounded-xl border-tableline">
          <div className="p-1 grid grid-cols-[20%_75%] gap-x-4 gap-y-3">
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-textsecondary">
              Series
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-right overflow-hidden whitespace-normal break-all">
              {firmwareInfo?.series ?? "???"}
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-textsecondary">
              ECU
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-right overflow-hidden whitespace-normal break-all">
              {firmwareInfo?.ecu ?? "???"}
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-textsecondary">
              Engine
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-right overflow-hidden whitespace-normal break-all">
              {firmwareInfo?.engine ?? "???"}
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-textsecondary">
              Software
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-right overflow-hidden whitespace-normal break-all">
              {firmwareInfo?.software ?? "???"}
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-textsecondary">
              Uploaded
            </p>
            <p className="md:text-sm xl:text-lg max-xl:text-lg text-right">
              {convertUTCDateToLocalDateString(firmwareInfo?.uploaded)}
            </p>
          </div>

          <button
            className="flex items-center justify-center border-primary border border-solid rounded-lg px-3 
              mt-5
              cursor-pointer hover:bg-primary h-11 w-full transition duration-300 ease-in-out"
            onClick={() => {
              setFirmwareInfo(null);
              setSelectedFile(null);
              setFileUploaded(false);
            }}
          >
            {t("Upload new file")}
          </button>
        </div>
      </div>
    </>
  );
};

export default UploadedFileInfo;
