import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableCell, TableHeader } from "../../table/tableCellHeader";
import { useParams } from "react-router-dom";
import useOfflineServiceStore from "../../../stores/admin/offlineServiceStore";
import OfflineService from "../../../api/admin/offlineService";
import OrderStatus from "../../common/orderStatus/orderStatus";
import { useModalConfirmActionStore } from "../../../stores/admin/modalConfirmActionStore";

const AdminTableServiceRecordInfo: React.FC = () => {
  const { t } = useTranslation();
  const { order, fetchOrder } = useOfflineServiceStore();

  const { file } = useParams<{ file: string }>();

  const offlineService = new OfflineService();
  const { setMessage, setModalOpen, setConfirmAction } =
    useModalConfirmActionStore();

  useEffect(() => {
    fetchOrder(Number(file));
  }, []);

  function downloadFile(id: any): void {
    offlineService.downloadFile(id);
  }

  function downloadEcuFile(id: any): void {
    offlineService.downloadEcuFile(id);
  }

  function cancelOrder(id: any): void {
    setMessage(t("Are you sure you want to cancel order") + " " + id + "?");
    setConfirmAction(async () => {
      const offlineService = new OfflineService();
      await offlineService.cancelOrder(id);
      await fetchOrder(id);
    });
    setModalOpen(true);
  }

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="rounded-xl overflow-hidden border border-tableline max-md:hidden">
          <table className="w-full">
            <thead className="bg-tablebg2">
              <tr>
                <TableHeader additionalClassNames="w-20">№</TableHeader>
                <TableHeader additionalClassNames="w-20">Email</TableHeader>
                <TableHeader additionalClassNames="w-20">Series</TableHeader>
                <TableHeader additionalClassNames="w-52">Ecu</TableHeader>
                <TableHeader additionalClassNames="w-20">Engine</TableHeader>
                <TableHeader additionalClassNames="w-52">Software</TableHeader>
                <TableHeader additionalClassNames="w-52">Options</TableHeader>
                <TableHeader additionalClassNames="w-32">Uploaded</TableHeader>
                <TableHeader additionalClassNames="w-32">Status</TableHeader>
                <TableHeader additionalClassNames="w-40">Action</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-tablebg" key="1">
                {order !== undefined && (
                  <>
                    <TableCell>№{order.id}</TableCell>
                    <TableCell>{order.email}</TableCell>
                    <TableCell>{order.series}</TableCell>
                    <TableCell>{order.ecu}</TableCell>
                    <TableCell>{order.engine}</TableCell>
                    <TableCell>{order.software}</TableCell>
                    <TableCell>
                      <div className="flex flex-wrap">
                        {order.options !== undefined &&
                          order.options.map((option: any, subindex: number) => (
                            <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
                              {option}
                            </p>
                          ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      {new Date(order.uploaded).toLocaleDateString("ru-RU")}
                    </TableCell>
                    <TableCell>
                      <OrderStatus status={order.status} />
                    </TableCell>
                    <TableCell>
                      <div className="flex-1">
                        <button
                          className="flex items-center justify-center border-primary 
                            border border-solid rounded-lg px-3 cursor-pointer bg-primary h-10 w-38 my-2"
                          onClick={() => downloadFile(order.id)}
                        >
                          {t("Download")}
                        </button>
                        {order.isTwoEcu === true && (
                          <button
                            className="flex items-center justify-center border-primary 
                            border border-solid rounded-lg px-3 cursor-pointer bg-primary h-10 w-38 my-2"
                            onClick={() => downloadEcuFile(order.id)}
                          >
                            {t("Download Ecu2")}
                          </button>
                        )}

                        <button
                          className="flex items-center justify-center border-orange-600 
                            border border-solid rounded-lg px-3 cursor-pointer bg-orange-600 h-10 w-38 my-2"
                          onClick={() => cancelOrder(order.id)}
                        >
                          {t("Cancel")}
                        </button>
                      </div>
                    </TableCell>
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="md:hidden">
          <div
            className="rounded-xl overflow-hidden border border-tableline bg-tablebg my-4 p-4
                  flex flex-wrap"
          >
            {order !== undefined && (
              <>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">№</p>
                  <p>№ {order.id}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Email</p>
                  <p>{order.email}</p>
                </div>

                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Series</p>
                  <p>{order.series}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">ECU</p>
                  <p>{order.ecu}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Engine</p>
                  <p>{order.engine}</p>
                </div>
                <div className="p-2 w-full">
                  <p className="text-textsecondary">Software</p>
                  <p>{order.software}</p>
                </div>
                <div className="p-2 w-full">
                  <p className="text-textsecondary">Options</p>
                  <div className="flex flex-wrap">
                    {order.options !== undefined &&
                      order.options.map((option: any, subindex: number) => (
                        <p className="py-1 px-2 m-1 text-primary bg-primary bg-opacity-30 rounded-xl">
                          {option}
                        </p>
                      ))}
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Uploaded</p>
                  <p> {new Date(order.uploaded).toLocaleDateString("ru-RU")}</p>
                </div>
                <div className="p-2 w-1/2">
                  <p className="text-textsecondary">Status</p>
                  <p>
                    {" "}
                    <OrderStatus status={order.status} />
                  </p>
                </div>
                <div className="flex w-full justify-center border-t border-tableline pt-4">
                  <button
                    className="flex items-center justify-center border-primary 
                            border border-solid rounded-lg px-3 cursor-pointer bg-primary h-10 w-11/12 my-2 mx-2"
                    onClick={() => downloadFile(order.id)}
                  >
                    {t("Download")}
                  </button>

                  {order.isTwoEcu === true && (
                    <button
                      className="flex items-center justify-center border-primary 
                          border border-solid rounded-lg px-3 cursor-pointer bg-primary h-10 w-11/12 my-2 mx-2"
                      onClick={() => downloadFile(order.id)}
                    >
                      {t("Download Ecu2")}
                    </button>
                  )}

                  <button
                    className={`text-center cursor-pointer rounded-lg h-10 w-11/12
                  bg-orange-600 hover:animate-pulse transition duration-300 ease-in-out my-2 mx-2`}
                    onClick={() => cancelOrder(order.id)}
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {order !== undefined &&
        order.comment !== undefined &&
        order.comment.length >= 1 && (
          <div className="flex flex-col border border-divider rounded-xl border-dashed my-4 p-2">
            <p className="text-lg p-2">
              {t("User left the following comment")}
            </p>
            <p className="p-2 text-textsecondary">{order.comment}</p>
          </div>
        )}
    </>
  );
};

export default AdminTableServiceRecordInfo;
