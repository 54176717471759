import React, { ChangeEvent, DragEvent, useState, useEffect } from "react";
import { useUploadStore } from "../../stores/uploadFirmwareStore";
import { useTranslation } from "react-i18next";
import UploadFirmwareService from "../../api/uploadFirmwareService";
import UploadedDashboard from "./uploadedDashboard";
import useBreadcrumbsStore from "../../stores/breadcrumbStore";
import { ConstantRoutes } from "../../core/constantRoutes";

function UploadFirmware() {
  const {
    selectedFile,
    setSelectedFile,
    setFirmwareInfo,
    fileUploaded,
    setFileUploaded,
    setMaxPatchPrice,
    setPlusPatchPrice,
    setCheckedOnlinePatches,
    setCheckedOfflinePatches,
    setCheckedTgFlashPatches,
    setComment,
    setStockFile,
    setFixFile,
    setFirmwareProcessResult,
    setTwoEcu,
    setSelectedEcuFile,
    setEcuTwoUploaded
  } = useUploadStore();
  const { t } = useTranslation();
  const [highlighted, setHighlighted] = useState(false);
  const [inWork, setInWork] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { addBreadcrumb, clearBreadcrumbs } = useBreadcrumbsStore();

  useEffect(() => {
    clearBreadcrumbs();
    addBreadcrumb({
      label: "File processing",
      path: ConstantRoutes.fileProcessing,
    });
    setInWork(false);
    setFileUploaded(false);
    setEcuTwoUploaded(false);
    setSelectedFile(null);
    setSelectedEcuFile(null);
  }, []);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      await uploadFile(file);
    }
  };

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      await uploadFile(file);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = () => {
    setHighlighted(false);
  };

  const uploadFile = async (file: File) => {
    if (file) {
      const uploader = new UploadFirmwareService();
      try {
        var firmwareInfo = await uploader.uploadFile(file, {
          onProgress: (progress) => {
            setUploadProgress(progress);
          },
        });
        setFileUploaded(true);
        setInWork(true);
        setSelectedFile(null);
        setFirmwareInfo(firmwareInfo);
        setUploadProgress(0);
        setCheckedOnlinePatches([]);
        setCheckedOfflinePatches([]);
        setCheckedTgFlashPatches([]);
        setMaxPatchPrice(null);
        setPlusPatchPrice(null);
        setComment(undefined);
        setStockFile(false);
        setFixFile(false);
        setFirmwareProcessResult(null);
        setTwoEcu(false);
        setSelectedEcuFile(null);
        setEcuTwoUploaded(false);
      } catch (error) {
        // Handle error
        console.error("Error uploading file:", error);
      }
    } else {
      // Handle case when no file is selected
      console.error("No file selected for upload.");
    }
  };

  return (
    <>
      {(!fileUploaded || !inWork) && (
        <div
          className={`flex-1 flex flex-col justify-center items-center border border-divider rounded-xl border-dashed h-72             
                ${highlighted ? "border-primary bg-content2" : ""}`}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          {selectedFile && (
            <div className="mt-4 relative">
              <div className="relative z-10">
                <h1 className="text-2xl pb-4"> {t("Upload progress")}</h1>
                <p className="hidden md:block text-textsecondary text-sm pb-4">
                  {t("Wait until the file is uploaded")}
                </p>

                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-tablebg2">
                  <div
                    style={{ width: `${uploadProgress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-primary transition-width duration-500 ease-in-out"
                  ></div>
                </div>
              </div>
            </div>
          )}

          {!selectedFile && (
            <div className="text-center">
              <h1 className="text-2xl pb-4">{t("Upload a new file")}</h1>
              <p className="hidden md:block text-textsecondary pb-4">
                {t(
                  "Drag the file to the highlighted area and click upload file"
                )}
              </p>
              <div className="flex justify-center">
                <div className="py-5">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="hidden"
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    className="text-center cursor-pointer py-5 px-14 rounded-lg bg-primary"
                  >
                    {t("Upload")}
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {fileUploaded && inWork && <UploadedDashboard />}
    </>
  );
}

export default UploadFirmware;
